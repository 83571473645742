import React from 'react';

import GooglePlay from '../assets/GooglePlay.webp';
import AppStore from '../assets/AppStore.webp';
import SPLogo from '../assets/splogo.webp';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-row">
        <div className="footer-column">
          <img src={SPLogo} alt="Logo" className="footer-logo" />
          <p className="footer-text">Your Sports Community App</p>
          <div className="footer-buttons">
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
              <img src={GooglePlay} alt="Google Play" className="footer-button" />
            </a>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
              <img src={AppStore} alt="App Store" className="footer-button" />
            </a>
          </div>
          <p className="footer-text2">SY NO 19, SY NO 190, GAUTAMI SOCIETY, Kondapur, Serilingampally, K.V.Rangareddy - 500084, Telangana</p>
          <p className="footer-text2">info@spotandplay.com</p>
          <p className="footer-text2">+91 99851 38678</p>
        </div>
      </div>
      <div className='copyright-text'>
      <div className="footer-row2">
        <p className="footer-text">IND-Datasparx Private Limited <br></br>© 2023 Spot &amp; Play. All Rights Reserved</p>
        <div className="footer-links-row">
          <a href="/termsandconditions" target='_blank'>Terms & Conditions</a>
          <a href="/reservationpolicy" target='_blank'>Reservation Policy</a>
          <a href="/cancellationpolicy" target='_blank'>Cancellation Policy</a>
          <a href="/privacypolicy" target='_blank'>Privacy Policy</a>
        </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;