import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Splogo from '../assets/splogo.webp';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavigationBarNoButton() {
  const navbarStyle = {
    backgroundColor: '#022d36',
    padding: '10px 20px',
    height: '65px',
  };

  return (
    <Navbar expand="lg" style={navbarStyle} variant="dark">
      <Navbar.Brand href="index.html">
        <img src={Splogo} alt="Logo" style={{ maxHeight: '65px' }} loading="lazy" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBarNoButton;
