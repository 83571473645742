import React, { useState, useEffect } from "react";

export const TermsAndConditions = () => {
    let [htmlFileString, setHtmlFileString] = useState();

    async function fetchHtml() {
      setHtmlFileString(await (await fetch(`TermsandConditions.html`)).text());
    }
    useEffect(() => {
      fetchHtml();
    }, []);
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
    </div>
  );
}
