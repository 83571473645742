import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import NavigationBar from './components/header';
import Body from './components/body';
import Info from './components/info';
import Cards from './components/amenities';
import Section from './components/section';
import Footer from './components/footer';
import LoginRegister from './components/loginregister';
import NavigationBarNoButton from './components/header_nobutton'; // Import the new component
import { TermsAndConditions } from './components/TermsAndConditionsPage';
import { CancellationPolicy } from './components/CancellationPolicyPage';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { ReservationPolicy } from './components/ReservationPolicyPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/loginregister" element={<LoginRegisterWithNavbar />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/reservationpolicy" element={<ReservationPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

// Component to render Home page with all components
function Home() {
  return (
    <>
      <NavigationBar />
      <Body />
      <Info />
      <Cards />
      <Section />
      <Footer />
    </>
  );
}

// Component to render LoginRegister page with NavigationBarNoButton
function LoginRegisterWithNavbar() {
  return (
    <>
      <NavigationBarNoButton />
      <LoginRegister />
    </>
  );
}

export default App;
