import React from 'react';
import screenshot_1 from '../assets/screenshot_1.jpg';
import screenshot_2 from '../assets/screenshot_2.jpg';
import './section.css';
const Section = () => {
  return (
    <div className='wrapper'>
      <div>
        <img className='section_image' src={screenshot_1} alt="#" />
        <div className='text_box'>
          <h2>Get Started</h2>
          <p>innovative sports app designed to revolutionize the way sports enthusiasts engage with their favorite activities. 
            Developed with a passion for promoting an active lifestyle, this app aims to motivate users of all skill levels to 
            jumpstart their fitness journey and unlock their full potential.</p>
        </div>
      </div>
      <div>
        <img className='section_image' src={screenshot_2} alt="#" />
        <div className='text_box'>
          <h2>Easy Booking</h2>
          <p>designed to simplify the way you book and engage in your favorite athletic activities. With a user-friendly interface 
             and a seamless booking process, this app aims to revolutionize the way sports enthusiasts discover, reserve, and participate
             in sports events.</p>
        </div>
      </div>
      <div>
        <img className='section_image' src={screenshot_2} alt="#" />
        <div className='text_box'>
          <h2>Secure Payment</h2>
          <p>Our sport phone app prioritizes the security and privacy of our users when it comes to payment transactions. With our
             secure payment system, you can enjoy a hassle-free and protected experience while making payments for various sports-related 
             activities.</p>
        </div>
      </div>
    </div>
  );
}

export default Section;
