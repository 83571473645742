import React from 'react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import Coach from '../assets/coach.png';
import Tournaments from '../assets/tournament.png';
import Teamup from '../assets/team_up.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './info.css'

function Body() {
  return (
    <div className="container body-container">
      <div className="row justify-content-center align-items-start my-4">
        <div className="col-md-6 col-lg-4">
          <div className="d-flex flex-column align-items-center">
            <div className="w-24 h-24 mb-2">
            <img src={Teamup} alt="Team up With players around you" width={100} height={100} />
            </div>
            <div className="font-semibold text-lg" style={{ fontSize: '20px', fontStyle: 'italic' }}>
              Team up With players around you
            </div>
          </div>
          <div className="d-flex flex-column align-items-center mt-5">
            <div className="w-24 h-24">
            <img src={Tournaments} alt="Train by the in the professionals" width={100} height={100} />
            </div>
            <div className="font-semibold text-lg mt-2" style={{ fontSize: '20px', fontStyle: 'italic' }}>
              Skill-up your game
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-4">
          <div className="d-flex flex-column align-items-center">
            <div className="w-24 h-24 mb-2">
              <HiOutlineLocationMarker size={100} color="orange" />
            </div>
            <div className="font-semibold text-lg" style={{ fontSize: '20px', fontStyle: 'italic' }}>
            Reserve Facilities of your interest
            </div>
          </div>
          <div className="d-flex flex-column align-items-center mt-5">
            <div className="w-24 h-24">
            <img src={Coach} alt="Train by the in the professionals" width={100} height={100} />
            </div>
            <div className="font-semibold text-lg mt-2" style={{ fontSize: '20px', fontStyle: 'italic' }}>
             Train by the in the professionals
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;