import React, { useState, useEffect } from "react";

export const ReservationPolicy = () => {
  let [htmlFileString, setHtmlFileString] = useState();

  async function fetchHtml() {
    setHtmlFileString(await (await fetch(`ReservationPolicy.html`)).text());
  }
  useEffect(() => {
    fetchHtml();
  }, []);
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
    </div>
  );
};
