import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Cricket from '../assets/cricket_card.jpg';
import VolleyBall from '../assets/volley_card.jpg';
import Badminton from '../assets/badminton_card.jpg';
import Tennis from '../assets/tennis_card.jpg';
import Football from '../assets/football_card.webp';
import Swimming from '../assets/swimming_card.jpg';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

function Cards() {
  return (
    <section className="pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-4">Our Sports Near You</h2>
            <Carousel
              id="carouselExampleIndicators2"
              className="carousel slide"
              data-ride="carousel"
              indicators={false}
              prevIcon={<FaChevronLeft style={{ fontSize: '2rem', color: 'black'}}/>}
              nextIcon={<FaChevronRight style={{ fontSize: '2rem', color: 'black'}}/>}
            >
              <Carousel.Item>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Card>
                      <Card.Img className="img-fluid" alt="100%x280" src={Cricket} />
                      <Card.Body>
                        <Card.Title>Cricket</Card.Title>
                        <Button variant="link">Centers</Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-4 mb-3">
                    <Card>
                      <Card.Img className="img-fluid" alt="100%x280" src={VolleyBall} />
                      <Card.Body>
                        <Card.Title>VolleyBall</Card.Title>
                        <Button variant="link">Centers</Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-4 mb-3">
                    <Card>
                      <Card.Img className="img-fluid" alt="100%x280" src={Swimming} />
                      <Card.Body>
                        <Card.Title>Swimming</Card.Title>
                        <Button variant="link">Centers</Button>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Card>
                      <Card.Img className="img-fluid" alt="100%x280" src={Badminton} />
                      <Card.Body>
                        <Card.Title>Badminton</Card.Title>
                        <Button variant="link">Centers</Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-4 mb-3">
                    <Card>
                      <Card.Img className="img-fluid" alt="100%x280" src={Football} />
                      <Card.Body>
                        <Card.Title>Soccer</Card.Title>
                        <Button variant="link">Centers</Button>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-4 mb-3">
                    <Card>
                      <Card.Img className="img-fluid" alt="100%x280" src={Tennis} />
                      <Card.Body>
                        <Card.Title>Tennis</Card.Title>
                        <Button variant="link">Centers</Button>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cards;
