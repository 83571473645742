import React from 'react';
import phone from '../assets/phone_section.webp';
import background from '../assets/background.webp';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaGooglePlay } from 'react-icons/fa';
import { FaApple } from 'react-icons/fa';
import './body.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Body() {
  const containerStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: 'calc(100vh - 230px)',
  };

  return (
    <div style={containerStyle}>
      <Container>
        <Row>
          <Col lg={6}>
            <div style={{
              marginTop: 85,
              alignItems: 'center',
              color: 'black',
            }}>
              <h1 className="heading">Download the App</h1>
              <p style={{ fontSize: 25 }}>Join the world's largest sports community, connecting millions of people to the sports they love.</p>
            </div>
            <Button variant="dark" size="lg" className="download-button">
              <a href="https://play.google.com/store" style={{ color: 'white', textDecoration: 'none' }}>
                <Row>
                  <Col lg={2}>
                    <FaGooglePlay style={{
                      fontSize: 55,
                      marginTop: 5,
                    }} />
                  </Col>
                  <Col lg={10}>
                    <p style={{ fontSize: 15 }}>GET IT ON</p>
                    Google Play
                  </Col>
                </Row>
              </a>
            </Button>
            <Button variant="dark" size="lg" className="download-button">
              <a href="https://www.apple.com/app-store/" style={{ color: 'white', textDecoration: 'none' }}>
                <Row>
                  <Col lg={2}>
                    <FaApple style={{
                      fontSize: 55,
                      marginTop: 5,
                    }} />
                  </Col>
                  <Col lg={10}>
                    <p style={{ fontSize: 15 }}>Download on the</p>
                    App Store
                  </Col>
                </Row>
              </a>
            </Button>
          </Col>
          <Col lg={6}>
            <img src={phone} alt="iphone-mockup" className="iphone-img" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Body;
