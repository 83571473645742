import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Splogo from '../assets/splogo.webp';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavigationBar() {
  const navbarStyle = {
    backgroundColor: '#022d36',
    padding: '10px 20px', // Adjust the padding as needed
    height: '70px', // Adjust the height as needed
  };

  // Use state to manage the button hover state
  const [isButtonHovered, setButtonHovered] = useState(false);
  const [isButtonHoveredTwo, setButtonHoveredTwo] = useState(false);

  const buttonStyle = {
    color: 'white', // Set the default text color to white
    backgroundColor: isButtonHovered ? 'orange' : 'transparent',
    border: 'none',
    transition: 'background-color 0.3s ease',
    fontSize: '20px', // Adjust the font size as needed
    padding: '12px 24px', // Adjust the padding as needed
  };

  const buttonStyleTwo = {
    color: 'white', // Set the default text color to white
    backgroundColor: isButtonHoveredTwo ? 'orange' : 'transparent',
    border: 'none',
    transition: 'background-color 0.3s ease',
    fontSize: '20px', // Adjust the font size as needed
    padding: '12px 24px', // Adjust the padding as needed
  };

  const handleButtonHover = () => {
    setButtonHovered(true); // Set the hover state to true
  };

  const handleButtonLeave = () => {
    setButtonHovered(false); // Set the hover state to false when leaving the hover state
  };

  return (
    <Navbar expand="lg" style={navbarStyle} variant="dark">
      <Navbar.Brand href="index.html">
        <img src={Splogo} alt="Logo" style={{ maxHeight: '55px' }} loading="lazy" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
        </Nav>
          <Button
            href='/resident/login'
            variant="outline-success"
            style={buttonStyle}
            className="btn-hover"
            onMouseEnter={handleButtonHover}
            onMouseLeave={handleButtonLeave}
          >
            Login
          </Button>
          <Button
            href='/resident/register'
            variant="outline-success"
            style={buttonStyleTwo}
            className="btn-hover"
            onMouseEnter={() => setButtonHoveredTwo(true)}
            onMouseLeave={() => setButtonHoveredTwo(false)}
          >
            Register
          </Button>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
